import React from "react";

import styled from "styled-components";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Container = styled.div`
	max-width: 100%;
	width: 1040px;
	margin: 0 auto;
	margin: 0 auto;
`;

const Privacy = () => {
	return (
		<>
			<Navbar />
			<Container>
				<h1>Privacy- en cookieverklaring Shardly</h1>
				<p>1 januari 2022</p>
				<br />
				<p>
					Jouw privacy is voor Shardly van groot belang. Wij houden
					ons dan ook aan de relevante wet- en regelgeving over
					privacy, waaronder de Algemene Verordening
					Gegevensbescherming (verder: AVG). Dit betekent dat wij:
				</p>
				<ul>
					<li>
						<strong>Onze doeleinden duidelijk vastleggen,</strong>{" "}
						voordat wij jouw persoonlijke gegevens verwerken, via
						deze privacyverklaring;
					</li>
					<li>
						<strong>
							Zo min mogelijk persoonlijke gegevens opslaan
						</strong>{" "}
						en enkel de gegevens die nodig zijn voor onze
						doeleinden;
					</li>
					<li>
						<strong>Expliciet toestemming vragen</strong> voor de
						verwerking van jouw persoonlijke gegevens, mocht
						toestemming verplicht zijn;
					</li>
					<li>
						<strong>
							Benodigde beveiligingsmaatregelen treffen
						</strong>{" "}
						om jouw persoonlijke gegevens te beschermen. Wij leggen
						deze verplichtingen ook op aan partijen die
						persoonsgegevens voor ons verwerken;
					</li>
					<li>
						<strong>Jouw rechten respecteren</strong>, zoals het
						recht op inzage, correctie of verwijdering van jouw bij
						ons verwerkte persoonsgegevens.
					</li>
				</ul>
				<p>
					Jouw gegevens zijn veilig bij ons en wij zullen deze
					gegevens altijd netjes gebruiken. In deze privacyverklaring
					leggen we uit wat wij bij het bedrijf Shardly allemaal doen
					met informatie die wij over jou te weten komen.
				</p>
				<p>
					Als je vragen hebt of wilt weten wat wij precies van jou
					bijhouden, neem dan contact op met Shardly.
				</p>
				<h4>Jouw account</h4>
				<p>
					Bij bepaalde onderdelen van ons bedrijf moet je je eerst
					registreren. Je moet dan informatie over jezelf opgeven en
					een gebruikersnaam kiezen. Daarmee maken wij een account,
					waarop je kunt inloggen met die gebruikersnaam en een
					zelfgekozen wachtwoord. Hiervoor gebruiken wij jouw:
				</p>
				<ul>
					<li>NAW-gegevens</li>
					<li>Telefoonnummer</li>
					<li>E-mailadres</li>
					<li>Geslacht</li>
					<li>Geboortedatum</li>
					<li>Nickname</li>
					<li>Profielfoto</li>
					<li>IP-adres</li>
				</ul>
				<p>
					Dit doen wij op basis van jouw toestemming. Wij bewaren deze
					informatie, tot je het account opheft. Wij bewaren deze
					informatie, zodat je ze niet steeds opnieuw in hoeft te
					vullen bij het bedrijf en zodat wij je makkelijker kunnen
					benaderen als dat nodig is. Je kunt via jouw account
					informatie aanpassen wanneer je dat wilt.
				</p>
				<p>
					<i>Verstrekken aan derden</i>
					<br />
					Wij werken met bepaalde bedrijven samen, die jouw hierboven
					genoemde persoonsgegevens van ons kunnen ontvangen.
				</p>
				<h4>Verzenden van nieuwsbrieven</h4>
				<p>
					Wij hebben een nieuwsbrief en je wordt alleen met jouw
					toestemming toegevoegd aan de lijst van abonnees. In de
					nieuwsbrief lees je nieuwtjes, tips en informatie over onze
					producten en diensten. Hiervoor gebruiken wij jouw:
				</p>
				<ul>
					<li>NAW-gegevens</li>
					<li>E-mailadres</li>
				</ul>
				<p>
					Dit doen wij op basis van jouw toestemming. Wij bewaren deze
					informatie, totdat je het abonnement opzegt.
				</p>
				<p>
					De nieuwsbrief wordt wekelijks per mail verstuurd. De inhoud
					van de nieuwsbrief bevat onder andere de volgende:
				</p>
				<ul>
					<li>Ontwikkelingen omtrent app</li>
				</ul>
				<p>
					Het abonnement op de nieuwsbrief kun je op ieder moment
					opzeggen. Iedere nieuwsbrief bevat een afmeldlink.
				</p>
				<p>
					<h4>Toegang portaal</h4>
					Met ons portaal krijg je toegang tot een beheeromgeving waar
					je zelf dingen kunt instellen, opgeven en wijzigen voor het
					bedrijf. Wij houden bij wat je gedaan hebt en wanneer, zodat
					daar bewijs van is. Hiervoor gebruiken wij jouw:
				</p>
				<ul>
					<li>NAW-gegevens</li>
					<li>Telefoonnummer</li>
					<li>E-mailadres</li>
					<li>Geslacht</li>
					<li>Geboortedatum</li>
					<li>Nickname</li>
					<li>Profielfoto</li>
					<li>IP-adres</li>
				</ul>
				<p>
					Dit doen wij op basis van jouw toestemming. Wij bewaren deze
					informatie, tot het einde van de dienstverlening aan jou.
				</p>
				<p>
					<i>Verstrekken aan derden</i>
					<br />
					Wij werken met bepaalde bedrijven samen, die jouw hierboven
					genoemde persoonsgegevens van ons kunnen ontvangen.
				</p>
				<h4>Contactformulier</h4>
				<p>
					Met het contactformulier kun je ons vragen stellen of
					aanvragen doen. Hiervoor gebruiken wij jouw:
				</p>
				<ul>
					<li>NAW-gegevens</li>
					<li>E-mailadres</li>
					<li>IP-adres</li>
				</ul>
				<p>
					Dit doen wij op basis van jouw toestemming. Wij bewaren deze
					informatie, totdat we zeker weten dat je tevreden bent met
					onze reactie.
				</p>
				<h4>Reclame</h4>
				<p>
					Wij willen jou graag reclame sturen over aanbiedingen en
					nieuwe producten of diensten. Dit doen wij:
				</p>
				<ul>
					<li>Per e-mail</li>
					<li>Via social media</li>
				</ul>
				<p>
					Je kunt op ieder moment bezwaar maken tegen deze reclame.
					Elke e-mail bevat een afmeldlink. Je kunt ons blokkeren of
					de afmeldoptie gebruiken. Via jouw account kun je dit ook
					doorgeven. Via het portaal kun je dit ook doorgeven.
				</p>
				<h4>Verstrekking aan andere bedrijven of instellingen</h4>
				<p>
					Met uitzondering van de partijen die nodig zijn om de
					hierboven genoemde diensten te leveren, geven wij jouw
					persoonsgegevens onder geen voorwaarde aan andere bedrijven
					of instellingen, behalve als wij dat wettelijk verplicht
					zijn (bijvoorbeeld als de politie dat eist bij een vermoeden
					van een misdrijf)
				</p>
				<h4>Statistieken</h4>
				<p>
					Wij houden statistieken bij over het gebruik van ons
					bedrijf, maar deze gegevens zijn altijd geanonimiseerd.
				</p>
				<h4>Cookies</h4>
				<p>
					Ons bedrijf gebruikt cookies. Cookies zijn kleine bestandjes
					waar we informatie in kunnen opslaan, zodat je die niet
					steeds hoeft in te vullen. Maar wij kunnen er ook mee zien
					dat je ons weer bezoekt.
				</p>
				<p>
					Je kunt via jouw browser het plaatsen van cookies
					uitschakelen, maar sommige dingen van ons bedrijf werken dan
					niet goed meer.
				</p>
				<p>
					Met andere bedrijven die cookies plaatsen hebben wij
					afspraken gemaakt over het gebruik van de cookies. Toch
					hebben wij geen volledige controle op wat zij zelf met de
					cookies doen. Lees dus ook hun privacyverklaringen.
				</p>
				<h4>Beveiliging</h4>
				<p>
					Beveiliging van persoonsgegevens is voor ons van groot
					belang. Wij zorgen dat jouw gegevens bij ons goed beveiligd
					zijn. Wij passen de beveiliging steeds aan en letten goed op
					wat er mis kan gaan.
				</p>
				<h4>Wijzigingen in deze privacyverklaring</h4>
				<p>
					Wanneer ons bedrijf wijzigt, moeten wij natuurlijk ook de
					privacyverklaring aanpassen. Let dus altijd op de datum
					hierboven en kijk regelmatig of er nieuwe versies zijn. Wij
					zullen ons best doen wijzigingen aan je door te geven.
				</p>
				<h4>Jouw rechten</h4>
				<p>
					Als je vragen hebt of wilt weten welke persoonsgegevens wij
					van jou hebben, kun je altijd contact met ons opnemen. Zie
					de contactgegevens hieronder.
				</p>
				<p>Je hebt de volgende rechten:</p>
				<ul>
					<li>
						Uitleg krijgen over welke persoonsgegevens wij hebben en
						wat wij daarmee doen;
					</li>
					<li>
						Inzage in de precieze persoonsgegevens die wij hebben;
					</li>
					<li>Het laten corrigeren van fouten;</li>
					<li>
						Het laten verwijderen van verouderde persoonsgegevens;
					</li>
					<li>
						Het laten overdragen van persoonsgegevens aan een andere
						partij;
					</li>
					<li>Intrekken van toestemming;</li>
					<li>Een bepaalde verwerking beperken;</li>
					<li>Bezwaar maken tegen een bepaald gebruik.</li>
				</ul>
				<p>
					Let op dat je altijd duidelijk aangeeft wie je bent, zodat
					we zeker weten dat wij geen gegevens van de verkeerde
					persoon aanpassen of verwijderen.
				</p>
				<p>
					Wij zullen in principe binnen een maand aan jouw verzoek
					voldoen. Deze termijn kan echter worden verlengd om redenen
					die verband houden met de specifieke rechten van betrokkenen
					of de complexiteit van het verzoek. Als wij deze termijn
					verlengen, zullen wij je daarvan tijdig op de hoogte
					stellen.
				</p>
				<h4>Klacht indienen</h4>
				<p>
					Als je een klacht in wil dienen over het gebruik van jouw
					persoonsgegevens kun je een e-mail sturen naar
					info@positievegezondheid.app. Wij pakken elke klacht intern
					op en communiceren dit verder met je.
				</p>
				<p>
					Als je vindt dat wij je niet op de juiste manier helpen, dan
					heb je het recht om een klacht in te dienen bij de
					toezichthouder. Deze heet de Autoriteit Persoonsgegevens.
				</p>
				<h4>Contactgegevens</h4>
				<p>
					Shardly
					<br />
					Kloosterweg 1<br />
					6412CN
					<br />
					Heerlen
					<br />
					E-mailadres: info@positievegezondheid.app
					<br />
					KvK nummer: 72495138
				</p>
			</Container>
			<Footer />
		</>
	);
};

export default Privacy;
